import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Divider } from '@mui/material';

import { sendFeedback } from '@src/api/rooms';
import { UiRoutes } from '@src/lib/constants';
import { Header } from '@src/screens/Booking/style';

import FeedbackDialog from '../Modal/FeedbackModal';

export const PageHeader = ({ onClick }: { onClick?: () => void }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleNavigation = () => {
    navigate(UiRoutes.HOME);
  };

  const handleMyBooking = () => {
    navigate(UiRoutes.MY_BOOKING);
  };

  return (
    <div style={{ position: 'sticky', top: 0, background: 'white', zIndex: '100' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <Header style={{ background: 'transparent' }}>
          <img src='/images/1000276066.png' alt='' onClick={onClick ? onClick : handleNavigation} />
        </Header>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={handleMyBooking}
            variant='outlined'
            style={{
              fontWeight: '700',
              color: 'black',
              padding: '6px 8px',
              textTransform: 'none',
              borderRadius: '5px',
              backgroundColor: 'yellow',
              fontSize: '14px',
            }}
          >
            My Bookings
          </Button>
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            variant='outlined'
            style={{
              fontWeight: '700',
              color: 'black',
              padding: '6px 8px',
              textTransform: 'none',
              borderRadius: '5px',
              backgroundColor: 'yellow',
              fontSize: '14px',
            }}
          >
            Feedback
          </Button>
        </div>
      </div>
      <FeedbackDialog
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        handleSubmit={async feedback => {
          try {
            setIsOpen(false);
            await sendFeedback(feedback);
          } catch (err) {
            console.log('err', err);
          }
        }}
      />
      <Divider />
    </div>
  );
};
