import { lazy } from 'react';

import { UiRoutes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const Home = lazy(() => import('../screens/Home'));
const Login = lazy(() => import('../screens/Login'));
const BookingPage = lazy(() => import('../screens/Booking'));
const VisitorInfo = lazy(() => import('../screens/VisitorInfo'));
const ParkingDirections = lazy(() => import('../screens/ParkingDirections'));
const MyBookings = lazy(() => import('../screens/MyBooking'));

const routesConfig: Array<RoutesConfigType> = [
  {
    path: UiRoutes.HOME,
    component: Home,
    layout: 'private',
  },
  {
    path: UiRoutes.LOGIN,
    component: Login,
    layout: 'public',
  },
  {
    path: UiRoutes.BOOKING,
    component: BookingPage,
    layout: 'public',
  },
  {
    path: UiRoutes.VISITOR_HELP,
    component: VisitorInfo,
    layout: 'public',
  },
  {
    path: UiRoutes.PARKING_DIRECTION,
    component: ParkingDirections,
    layout: 'public',
  },
  {
    path: UiRoutes.MY_BOOKING,
    component: MyBookings,
    layout: 'public',
  },
];

export default routesConfig;
