/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { callMsGraph } from '@src/api/graph';
import { loginRequest } from '@src/config/authConfig';
import { dateOptions } from '@src/lib/constants';

import { GridContainer, StyledChip } from './style';

export const Calendar = ({
  handleSelect,
  selectedDate,
}: {
  handleSelect: (key: string, value: string) => void;
  selectedDate: string;
}) => {
  const [selectedDatePref, setSelectedDatePref] = useState('');
  const [selectedNewDate, setSelectedDate] = useState<any>(null);
  const [multiPersonBooking, setMultiPersonBooking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphUsersData, setGraphUsersData] = useState<any>(null);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  // Handle login via MSAL
  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch(e => {
      console.error('Login failed:', e);
    });
  };

  // Handle logout via MSAL
  const handleLogout = () => {
    instance.logoutPopup().catch(e => {
      console.error('Logout failed:', e);
    });
  };

  // Handle multi-person booking checkbox click
  const handleCheckboxChange = () => {
    setMultiPersonBooking(!multiPersonBooking);

    if (multiPersonBooking || isAuthenticated) {
      handleLogout();
    }

    if (!multiPersonBooking && !isAuthenticated) {
      handleLogin();
    }
  };

  // Fetch user data from Microsoft Graph API
  const fetchUserData = async () => {
    if (accounts.length > 0) {
      setLoading(true);

      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        // Fetch users from Graph API
        const userData = await callMsGraph(response.accessToken);
        localStorage.setItem('graphAccessToken', response.accessToken);
        setGraphUsersData(userData);
        console.log('>>>graphUsersData', graphUsersData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated && multiPersonBooking) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, multiPersonBooking]);

  useEffect(() => {
    if (selectedNewDate && selectedDatePref) {
      handleSelect('selectedDate', selectedNewDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatePref, selectedNewDate]);

  return (
    <div>
      {/* Calendar Selection */}
      <div style={{ marginBottom: '30px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            views={['day']}
            value={selectedNewDate ? dayjs(selectedNewDate) : selectedDate ? dayjs(selectedDate) : null}
            sx={{
              '&.MuiDateCalendar-root': {
                margin: '0',
                width: '100%',
                '.MuiDayCalendar-root': {
                  '.MuiDayCalendar-weekDayLabel': {
                    flex: 1,
                    fontWeight: '700',
                    fontSize: '15px',
                  },
                  '.MuiPickersDay-root': {
                    flex: 1,
                    fontSize: '15px',
                    borderRadius: '0px',
                    height: '40px',
                    fontWeight: '700',
                  },
                },
                '.MuiPickersCalendarHeader-root': {
                  '.MuiPickersCalendarHeader-label': {
                    fontWeight: '700',
                    fontSize: '15px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  },
                  padding: '20px 12px 20px 24px',
                  background: 'purple',
                  color: 'white',
                  '.MuiSvgIcon-root': {
                    color: 'white',
                  },
                },
                '.MuiPickersCalendarHeader-switchViewButton': {
                  display: 'none',
                },
                '.Mui-selected': {
                  background: 'green',
                },
              },
            }}
            onChange={newValue => {
              setSelectedDate(newValue);

              if (selectedDatePref) {
                handleSelect('selectedDate', newValue || '');
              }
            }}
          />
        </LocalizationProvider>
      </div>

      {/* Date Option Chips */}
      <GridContainer $columnNumber={2}>
        {dateOptions?.map(({ id, name, isDisable, secondName }) => (
          <StyledChip
            key={id}
            onClick={() => {
              if (!isDisable) {
                setSelectedDatePref(id);

                if (selectedNewDate) {
                  handleSelect('selectedDate', selectedNewDate);
                }
              }
            }}
            $isDisable={isDisable}
            $isSelected={selectedDatePref === id}
            $isLocation
            style={{ flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {name}
            {secondName ? <span style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>{secondName}</span> : null}
          </StyledChip>
        ))}
        <StyledChip
          $isDisable
          $isLocation
          onClick={handleCheckboxChange}
          style={{ flexDirection: 'row', flexWrap: 'wrap' }}
        >
          Multi-person Booking
        </StyledChip>
      </GridContainer>

      {isAuthenticated && multiPersonBooking && (
        <div>
          {loading ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>
          ) : null}
        </div>
      )}
    </div>
  );
};
