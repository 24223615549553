const apiEndpoints = {
  USER_DETAILS: '/userData',
  USER: '/users',
  LOCATION_LIST: '/locations',
  FIND_ROOM: '/rooms/find/room',
  BOOK_ROOM: '/rooms/booking',
  VARIFY_OTP: '/rooms/varifyUser',
  FLOOR_NUMBER: '/rooms/floors',
  ROOM: '/rooms',
  BOOKINGS: '/bookings',
  BOOKINGS_BY_IDS: '/bookings/getBookingsByIds',
  GET_USER_BY_NAME: '/users/findUserByName',
  GET_BOOKING_TOKEN: '/auth/get-user-access-token',
  USER_BOOKING: '/bookings/userBookings',
  MAX_SIZE_ROOM: '/rooms/find-max-size-room',
  SEND_FEEDBACK: '/users/send-feedback',
};

export default apiEndpoints;
